import LanguageSelector from 'ui/LanguageSelector';
import styles from './RegistrationPrompt.module.scss';
import IntroLogo from 'assets/images/auth/intro-logo.svg';
import IntroImage from 'assets/images/auth/intro.png';
import NftIcon from 'assets/images/auth/nft.svg';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import ArrowIcon from 'assets/images/auth/arrow.svg';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useRedirectPath } from 'features/auth/hooks';

const LABELS_LIST = [
  {
    icon: NftIcon,
    label: 'Collect NFTs',
    type: 'icon',
  },
  {
    icon: '🤝',
    label: 'Discover the best deals',
    type: 'text',
  },
  {
    icon: '🗺️',
    label: 'Save money on your trips',
    type: 'text',
  },
];

const STORAGE_SEEN_KEY = 'seenPrompt';

interface Props {
  children: ReactNode;
}

export const RegistrationPrompt = ({ children }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.user);

  useRedirectPath();

  const setSeen = () => {
    localStorage.setItem(STORAGE_SEEN_KEY, 'true');
  };

  const onGoBack = useCallback(() => {
    setSeen();
    setIsOpened(false);
  }, [window.history.length]);

  const onStart = useCallback(() => {
    setSeen();
    navigate('/register');
    setIsOpened(false);
  }, []);

  useEffect(() => {
    const seen = localStorage.getItem(STORAGE_SEEN_KEY);

    if (!user && !seen && pathname.includes('/event')) {
      setIsOpened(true);
    }
  }, [user, pathname]);

  return (
    <>
      <div className={classNames(styles.intro, { [styles.opened]: isOpened })}>
        <div className={styles.content}>
          <LanguageSelector className={styles.languages} />

          <img src={IntroLogo} alt="" style={{ marginBottom: '40px' }} />

          <div className={styles.labels}>
            {LABELS_LIST.map(({ label, icon, type }) => (
              <p key={label}>
                {type === 'icon' ? <img src={icon} alt="" /> : <span>{icon}</span>}
                {t(label)}
              </p>
            ))}
          </div>

          <img src={IntroImage} alt="" style={{ marginBottom: '40px' }} />

          <div className={styles.buttons}>
            <Button
              text={t('Start')}
              color="white"
              icon={ArrowIcon}
              iconPosition="end"
              onClick={onStart}
            />
            <Button text={t('Continue as Guest')} color="transparent" onClick={onGoBack} />
          </div>
        </div>
      </div>

      {children}
    </>
  );
};
