import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const STORAGE_PATH_KEY = 'registeredFrom';

export const useRedirectPath = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setPath = () => {
    localStorage.setItem(STORAGE_PATH_KEY, pathname);
  };

  const getPath = () => {
    return localStorage.getItem(STORAGE_PATH_KEY);
  };

  const removePath = () => {
    localStorage.removeItem(STORAGE_PATH_KEY);
  };

  useEffect(() => {
    if (pathname.includes('/event')) {
      setPath();
    } else if (!pathname.includes('/register') && !pathname.includes('/login')) {
      removePath();
    }
  }, [pathname]);

  const redirectAfterAuth = () => {
    const redirectPath = getPath() ?? '/my-experiences';
    removePath();
    navigate(redirectPath);
  };

  return { redirectAfterAuth };
};
