import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from 'redux/features/user/userSlice';
import { eventSlice } from 'redux/features/event/eventSlice';
import { mapSlice } from 'redux/features/map/mapSlice';
import { publicInfoSlice } from 'redux/features/publicInfo/publicInfoSlice';
import { myExperiencesSlice } from './features/myExperiences/myExperiencesSlice';
import { experiencesOverviewSlice } from './features/admin/adminSlice';
import { managerSlice } from './features/manager/managerSlice';
import { api } from './api';
import { authSlice } from './features/auth';

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    user: userSlice.reducer,
    event: eventSlice.reducer,
    map: mapSlice.reducer,
    publicInfo: publicInfoSlice.reducer,
    myExperiences: myExperiencesSlice.reducer,
    admin: experiencesOverviewSlice.reducer,
    manager: managerSlice.reducer,
    api: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),

  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
