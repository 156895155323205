import axios from 'api/axios';
import type { AxiosResponse } from 'axios';
import type { SupportedLocale, EventCollection } from 'types/utilityApi';

const UtilityApi = {
  /**
   * API to list all supported locales.
   * Security roles - Anybody.
   *
   * @return {SupportedLocale[]} - List of locales.
   */
  async getLocalesList(): Promise<AxiosResponse<SupportedLocale[]>> {
    return axios.get('/public/locales/list');
  },

  /**
   * API to retrieve event collections.
   * Security roles - Anybody.
   *
   * @param [data]
   * @param {number[]} [data.eventIdSet] - Event id set.
   *
   * @return {EventCollection[]} - List of events collections
   */
  async getEventCollections(
    data: { eventIdSet?: number[] } = {}
  ): Promise<AxiosResponse<EventCollection[]>> {
    return axios.post('/public/collections/list', data);
  },
} as const;

export default UtilityApi;
