import { useTranslation } from 'react-i18next';
import styles from './LanguageSelector.module.scss';
import DropdownIcon from 'assets/images/auth/dropdown.svg';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Português', value: 'pt' },
  { label: 'Українська', value: 'uk' },
  { label: 'اللغة العربية', value: 'ar' },
  { label: 'Русский', value: 'ru' },
];

interface Props {
  className?: string;
}

const LanguageSelector = ({ className }: Props) => {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleIsOpened = () => setIsOpened((prev) => !prev);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpened(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    setIsOpened(false);
  };

  return (
    <div className={classNames(styles.container, className)} ref={containerRef}>
      <button className={styles.button} onClick={toggleIsOpened}>
        {language}{' '}
        <img src={DropdownIcon} alt="" style={{ transform: `rotate(${isOpened ? 180 : 0}deg)` }} />
      </button>

      {isOpened && (
        <div className={styles.languages}>
          {LANGUAGES.filter(({ value }) => value !== language).map((lang) => (
            <button
              key={lang.value}
              className={styles.lang}
              onClick={() => onChangeLanguage(lang.value)}
            >
              {lang.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
