import { useTranslation } from 'react-i18next';
import styles from './AuthLayout.module.scss';
import { Link } from 'react-router-dom';
import BackArrow from 'assets/images/icons/back-arrow.svg';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { authSelector, setAuthResetStage } from 'redux/features/auth';
import { ResetStage } from 'features/auth';
import { useEffect } from 'react';

const ResetPasswordHeader = () => {
  const { t } = useTranslation();
  const {
    reset: { stage },
  } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      if (stage === ResetStage.Completed) {
        dispatch(setAuthResetStage(ResetStage.Email));
      }
    };
  }, [stage]);

  return (
    <div className={styles.headerContainer} style={{ alignItems: 'flex-start' }}>
      <span>
        <Link to="/login">
          <img src={BackArrow} alt="" />
          {t('Back')}
        </Link>
      </span>

      <h1>
        {(stage === ResetStage.Email || stage === ResetStage.Code) && t('Reset Password')}
        {stage === ResetStage.Password && t('Set New Password')}
        {stage === ResetStage.Completed && t('Success!')}
      </h1>

      <p>
        {stage === ResetStage.Email && t('Enter your email address to receive code')}
        {stage === ResetStage.Code &&
          t('Check your email and enter the code you received to reset your password')}
        {stage === ResetStage.Password &&
          t(
            'Please enter your new password. For security reasons, you may need to confirm it by entering it twice.'
          )}
        {stage === ResetStage.Completed &&
          t(
            'Your new password has been successfully saved. You can now log in to your account using your updated credentials.'
          )}
      </p>
    </div>
  );
};

export default ResetPasswordHeader;
