import './HomeLayout.scss'
import type { FC } from 'react'
import { lazy } from 'react'
import { Outlet, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks'
import ScrollToTopButton from 'components/ScrollToTopButton'
import withSuspense from 'components/WithSuspense'
const Header = withSuspense(lazy(() => import('./components/Header/Header')))
const Footer = withSuspense(lazy(() => import('./components/Footer/Footer')))
const KaizenPage = withSuspense(lazy(() => import('pages/home/pages/kaizen')))
const KaizenHeader = withSuspense(lazy(() => import('pages/home/pages/kaizen/components/Header/Header')))
const SubjektivPage = withSuspense(lazy(() => import('pages/home/pages/paris-subjektiv-indoor')))
const TbilisiToursPage = withSuspense(lazy(() => import('pages/home/pages/tbilisi-tours')))


const HomeLayout: FC = () => {
  const { event } = useAppSelector((state) => state.event)
  const { eventName, additionalName } = useParams() as { eventName?: string, additionalName?: string }

  if (eventName && eventName === 'ukraine' && additionalName && additionalName === 'wauartfusion') {
    return (
      <div className="HomeLayout">
        <KaizenHeader />
        <KaizenPage />
        <Footer />
        <ScrollToTopButton />
      </div>
    )
  }

  if (eventName === 'paris-subjektiv-indoor') {
    return (
      <div className="HomeLayout">
        <Header event={event} />
        <SubjektivPage />
        <Footer />
        <ScrollToTopButton />
      </div>
    )
  }

  if (eventName === 'tbilisi-tours') {
    return (
      <div className="HomeLayout">
        <Header event={null} />
        <TbilisiToursPage />
        <ScrollToTopButton />
      </div>
    )
  }

  return (
    <div className="HomeLayout">
      <Header event={event} />
      <Outlet />
      <Footer />
      <ScrollToTopButton />
    </div>
  )
}

export default HomeLayout
