import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getToken from 'utils/getToken';

export const api = createApi({
  reducerPath: 'api', // The name of the slice in your Redux store
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PUBLIC_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
    credentials: 'include', // Send cookies with cross-origin requests
  }),
  tagTypes: [], // Add tag types for caching and invalidation,
  endpoints: () => ({})
});