import { useCallback, useEffect, useState } from 'react';
import type { MouseEvent, ChangeEvent, FC, InputHTMLAttributes, ReactElement, Ref } from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

import VisibilityOff from 'assets/images/auth/visibility_off.svg';
import VisibilityOn from 'assets/images/auth/visibility_on.svg';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: 'text' | 'password' | 'email' | 'wallet';
  className?: string;
  disabled?: boolean;
  adornment?: ReactElement;
  error?: string;
  variant?: 'underline' | 'outline';
  _ref?: Ref<HTMLLabelElement>;
  label?: string;
}

const Input: FC<inputProps> = (props) => {
  const {
    value,
    type = 'text',
    disabled = false,
    onChange = (): void => {},
    className,
    _ref,
    error,
    label,
    ...otherProps
  } = props;

  const [inputType, setInputType] = useState('text');

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const onVisibilitySwitch = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setInputType((prev) => (prev === 'text' ? 'password' : 'text'));
  }, []);

  return (
    <label
      className={classNames(styles.input, className, {
        [styles.error]: error?.length,
        [styles.disabled]: disabled,
      })}
      ref={_ref}
    >
      {label?.length && <p className={styles.label}>{label}</p>}

      <div className={styles.body}>
        <input
          type={inputType}
          value={value}
          disabled={disabled}
          onChange={onChange}
          {...otherProps}
        />

        {type === 'password' && (
          <button className={styles.visibility} onClick={onVisibilitySwitch}>
            <img src={inputType === 'password' ? VisibilityOff : VisibilityOn} alt="" />
          </button>
        )}
      </div>
      {error && (
        <div className={styles.error} title={error}>
          {error}
        </div>
      )}
    </label>
  );
};

export default Input;
