import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  PublicInfoState,
  setEventRoutePayload,
  setEventsOverviewPayload,
  setEventsPayload,
  setPartnersPayload,
} from './types';
import { getLocalesList } from './publicInfoActions';
import type { SupportedLocale } from 'types/utilityApi';
import type { PageableResponse } from 'types/api';

const initialState: PublicInfoState = {
  events: null,
  partners: null,
  locales: null,
  eventsOverview: null,
  eventRoutes: null,
  eventsOverviewPagination: null,
};

export const publicInfoSlice = createSlice({
  name: 'publicInfo',
  initialState,
  reducers: {
    setEvents: (state, action: setEventsPayload) => {
      const { payload } = action;
      let content = [];

      // Check if pagination changed
      if (
        state.events &&
        payload.pagination.page === state.events.pagination.page &&
        payload.pagination.pageSize === state.events.pagination.pageSize &&
        !payload.withReset
      ) {
        return;
      }

      if (payload.withReset) {
        content = payload.content;
      } else {
        content = state.events?.content.length
          ? [...state.events.content, ...payload.content]
          : payload.content;
      }

      state.events = { content, pagination: payload.pagination };
    },
    setPartners: (state, action: setPartnersPayload) => {
      const { payload } = action;
      let content = [];

      // Check if pagination changed
      if (
        state.partners &&
        payload.pagination.page === state.partners.pagination.page &&
        payload.pagination.pageSize === state.partners.pagination.pageSize &&
        !payload.withReset
      ) {
        return;
      }

      if (payload.withReset) {
        content = payload.content;
      } else {
        content = state.partners?.content.length
          ? [...state.partners.content, ...payload.content]
          : payload.content;
      }

      state.partners = { content, pagination: payload.pagination };
    },
    setEventsOverview: (state, action: setEventsOverviewPayload) => {
      const {
        payload: { events },
      } = action;
      state.eventsOverview = events;
    },
    setEventsOverviewPagination: (state, action: PayloadAction<PageableResponse>) => {
      state.eventsOverviewPagination = action.payload;
    },
    setEventRoute: (state, action: setEventRoutePayload) => {
      const {
        payload: { route, id },
      } = action;
      if (!state.eventRoutes) {
        state.eventRoutes = { [route]: id };
        return;
      }

      state.eventRoutes = { ...state.eventRoutes, [route]: id };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocalesList.fulfilled, (state, action: PayloadAction<SupportedLocale[]>) => {
      state.locales = action.payload;
    });
  },
});

export const {
  setEvents,
  setPartners,
  setEventsOverview,
  setEventRoute,
  setEventsOverviewPagination,
} = publicInfoSlice.actions;
