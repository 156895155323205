import HeaderImage from 'assets/images/auth/auth-image.png';
import styles from './AuthLayout.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  text: string;
  link: string;
  linkText: string;
  withImage?: boolean;
}

const AuthHeader = ({ link, linkText, text, title, withImage }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.headerContainer}>
      {withImage && <img src={HeaderImage} alt="" />}
      <h1>{t(title)}</h1>
      <p>
        {t(text)} <Link to={link}>{t(linkText)}</Link>
      </p>
    </div>
  );
};

export default AuthHeader;
