/* eslint-disable @typescript-eslint/indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ResetStage, type AuthResetPasswordForm } from '../types';
import {
  useApplyPasswordResetCodeMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
} from 'redux/api/auth';
import { useRenderApiError } from 'hooks/useRenderApiError';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  authSelector,
  setAuthResetCode,
  setAuthResetEmail,
  setAuthResetStage,
  setAuthResetTimer,
} from 'redux/features/auth';

const getValidationSchema = (stage: ResetStage) => {
  return yup.object().shape({
    email:
      stage === ResetStage.Email
        ? yup.string().email('Enter a valid email address.').required('Email is required.')
        : yup.string(),

    newPassword:
      stage === ResetStage.Password
        ? yup
            .string()
            .min(8, 'Password must be at least 8 characters long.')
            .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
            .matches(/\d/, 'Password must contain at least one number.')
            .matches(/[^a-zA-Z\d]/, 'Password must contain at least one special character.')
            .required('Password is required.')
        : yup.string(),

    repeatPassword:
      stage === ResetStage.Password
        ? yup
            .string()
            .oneOf([yup.ref('newPassword')], 'Passwords do not match.')
            .required('Repeat password is required.')
        : yup.string(),
  });
};

export const useResetPassword = () => {
  const [error, setError] = useState('');

  const [requestPasswordResetCode] = useRequestPasswordResetMutation();
  const [applyPasswordResetCode] = useApplyPasswordResetCodeMutation();
  const [resetPassword] = useResetPasswordMutation();
  const { extractMessage } = useRenderApiError();

  const {
    reset: { stage, code, email, timer },
  } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const setStage = (stage: ResetStage) => dispatch(setAuthResetStage(stage));
  const setCode = (code: string) => dispatch(setAuthResetCode(code));
  const setEmail = (email: string) => dispatch(setAuthResetEmail(email));
  const setTimer = (timer: number) => dispatch(setAuthResetTimer(timer));

  const form = useForm({
    resolver: yupResolver(getValidationSchema(stage)),
  });

  useEffect(() => {
    if (timer === 0) return;

    const interval = setInterval(() => {
      dispatch(setAuthResetTimer(timer - 1));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, dispatch]);

  const resendCode = () => {
    if (email) {
      requestPasswordResetCode(email).unwrap();
      setTimer(60);
    }
  };

  const onSubmit: SubmitHandler<AuthResetPasswordForm> = async (data) => {
    try {
      setError('');

      if (stage === ResetStage.Email) {
        await requestPasswordResetCode(data.email || '').unwrap();
        setStage(ResetStage.Code);
        setEmail(data.email || '');
        setTimer(60);
      }

      if (stage === ResetStage.Code) {
        await applyPasswordResetCode({ email, code }).unwrap();
        setStage(ResetStage.Password);
      }

      if (stage === ResetStage.Password) {
        await resetPassword({
          code,
          newPassword: data.newPassword || '',
          repeatPassword: data.repeatPassword || '',
        }).unwrap();
        setStage(ResetStage.Completed);
      }
    } catch (error) {
      setError(extractMessage(error));
    }
  };

  return {
    form,
    error,
    code: { value: code, setCode },
    stage,
    onSubmit: form.handleSubmit(onSubmit),
    resendCode,
    resendTimer: timer,
  };
};
