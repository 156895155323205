import { Outlet, useNavigate } from 'react-router-dom';
import styles from './AuthLayout.module.scss';
import Header from 'ui/Header';
import AuthHeader from './AuthHeader';
import ResetPasswordHeader from './ResetPasswordHeader';
import { useAppSelector } from 'redux/hooks';
import { authSelector } from 'redux/features/auth';
import { ResetStage } from 'features/auth';
import Button from 'ui/Button';
import { useTranslation } from 'react-i18next';
import LogInIcon from 'assets/images/auth/arrow.svg';

interface Props {
  page?: 'login' | 'register' | 'reset';
}

const AuthLayout = ({ page }: Props) => {
  const {
    reset: { stage },
  } = useAppSelector(authSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.layout}>
      <Header />

      {page === 'login' && (
        <AuthHeader
          link="/register"
          linkText="Create Account"
          text="New user?"
          title="Get Started"
          withImage
        />
      )}
      {page === 'register' && (
        <AuthHeader
          link="/login"
          linkText="Log In"
          text="Already have an account?"
          title="Create Account"
        />
      )}
      {page === 'reset' && <ResetPasswordHeader />}

      {page === 'reset' && stage === ResetStage.Completed ? (
        <div className={styles.completeBox}>
          <Button
            text={t('Log In')}
            color="white"
            icon={LogInIcon}
            iconPosition="end"
            onClick={() => {
              navigate('/login');
            }}
          />
        </div>
      ) : (
        <div className={styles.content}>
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default AuthLayout;
