import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Account, TokenInfo } from 'types/account';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

type initialStateType = {
  user: Account | null;
  isLoading: boolean;
  wasLoadedOnce: boolean;
};

const initialState: initialStateType = {
  user: null,
  isLoading: true,
  wasLoadedOnce: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<TokenInfo>) => {
      cookies.set(action.payload.tokenHeader, action.payload.token, {
        maxAge: 60 * 60 * 24 * 14,
        path: '/',
      });
    },
    removeToken: (state) => {
      cookies.remove('Bearer', { path: '/' });
      localStorage.removeItem('visitorToken');
      state.user = null;
      state.wasLoadedOnce = false;
      state.isLoading = false;
    },
    setAccount: (state, action: PayloadAction<Account>) => {
      state.user = action.payload;
    },
    setAvatar: (state, action: PayloadAction<Account['profileImage']>) => {
      if (state.user) {
        state.user.profileImage = action.payload;
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setWasLoadedOnce: (state, action: PayloadAction<boolean>) => {
      state.wasLoadedOnce = action.payload;
    },
  },
});

export const { setToken, removeToken, setAccount, setAvatar, setIsLoading, setWasLoadedOnce } =
  userSlice.actions;
