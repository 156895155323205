import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './SlideModalContext.module.scss';

import CloseIcon from 'assets/images/icons/close-icon.svg';
import classNames from 'classnames';

interface SliceModalState {
  openModal: (element: ReactNode) => void;
  closeModal: () => void;
  isOpened: boolean;
}

const SlideModalContext = createContext<SliceModalState>({} as SliceModalState);

export const useSlideModal = () => useContext(SlideModalContext);

interface ProviderProps {
  children: ReactNode;
}

export const SlideModalContextProvider = ({ children }: ProviderProps) => {
  const [element, setElement] = useState<ReactNode | null>(null);

  const openModal = useCallback((element: ReactNode) => {
    setElement(element);
  }, []);

  const closeModal = useCallback(() => {
    setElement(null);
  }, []);

  const value = useMemo(
    () => ({
      openModal,
      closeModal,
      isOpened: element !== null,
    }),
    [openModal, closeModal, element]
  );

  useEffect(() => {
    document.body.style.overflowY = element ? 'hidden' : 'scroll';
  }, [element]);

  return (
    <SlideModalContext.Provider value={value}>
      <div className={classNames(styles.modal, { [styles.opened]: element !== null })}>
        <div className={classNames(styles.element, { [styles.openedElement]: element !== null })}>
          <button className={styles.close} onClick={closeModal}>
            <img src={CloseIcon} alt="" />
          </button>
          {element}
        </div>
      </div>
      {children}
    </SlideModalContext.Provider>
  );
};
