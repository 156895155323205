import { Controller } from 'react-hook-form';
import styles from './SignUpForm.module.scss';
import Input from 'ui/Input';
import { useTranslation } from 'react-i18next';
import { useGoogleAuth } from 'hooks/useGoogleAuth';
import { useSignUpForm } from 'features/auth/hooks';
import Button from 'ui/Button';
import type { CSSProperties } from 'react';

import GoogleIcon from 'assets/images/icons/google.svg';
import LoginIcon from 'assets/images/icons/login.svg';
import Separator from 'ui/Separator';
import type { TokenInfo } from 'types/account';

interface Props {
  onSuccess?: (tokens: TokenInfo) => void;
  buttonText?: string;
  style?: CSSProperties;
}

export const SignUpForm = ({ buttonText, onSuccess, style }: Props) => {
  const { t } = useTranslation();
  const { googleLogin } = useGoogleAuth();

  const {
    control,
    formState: { errors, isSubmitting },
    onSubmit,
  } = useSignUpForm(onSuccess);

  return (
    <form onSubmit={onSubmit} className={styles.registerPage} style={style}>
      <div className={styles.inputs}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              placeholder={t('Enter email address')}
              label={t('Email Address')}
              disabled={isSubmitting}
              error={errors.email?.message && t(errors.email?.message)}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              placeholder={t('Enter your password')}
              label={t('Password')}
              disabled={isSubmitting}
              error={errors.password?.message && t(errors.password?.message)}
            />
          )}
        />

        <Controller
          name="repeatPassword"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              placeholder={t('Re-enter your password')}
              label={t('Confirm Password')}
              disabled={isSubmitting}
              error={errors.repeatPassword?.message && t(errors.repeatPassword?.message)}
            />
          )}
        />
      </div>

      <div className={styles.buttons}>
        <Button
          type="submit"
          text={buttonText || t('Create account')}
          variant="filled"
          color="primary"
          loading={isSubmitting}
          disabled={!!errors.email}
          icon={LoginIcon}
          iconPosition="end"
        />

        <Separator text={t('or sign up with')} />

        <Button
          type="button"
          text={t('Google')}
          variant="outlined"
          color="primary"
          className="submit"
          loading={isSubmitting}
          icon={GoogleIcon}
          iconPosition="start"
          onClick={(e) => {
            e.preventDefault();
            googleLogin();
          }}
        />
      </div>
    </form>
  );
};
