import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'
import {
  removeToken,
  setAccount,
  setIsLoading,
  setToken,
  setWasLoadedOnce,
} from 'redux/features/user/userSlice'
import AccountApi from 'api/accountApi'
import type {
  Account,
  ChangePasswordParams,
  ResetPasswordParams,
  SignInParams,
  SignUpParams,
  TokenInfo
} from 'types/account'
import type { StartExperienceParams } from 'types/experience'

export const getAccount = createAsyncThunk(
  'user/getAccount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      const response = await AccountApi.getAccount()
      dispatch(setAccount(response.data))
      return response.data as Account
    } catch (error: unknown) {
      dispatch(removeToken())
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
      dispatch(setWasLoadedOnce(true))
    }
  },
)

export const signIn = createAsyncThunk(
  'user/signIn',
  async (data: SignInParams, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      const response = await AccountApi.signIn(data)
      dispatch(setToken(response.data))
      await dispatch(getAccount())
      return response.data as TokenInfo
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const signInVisitor = createAsyncThunk(
  'user/signInVisitor',
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await AccountApi.signInVisitor(token)
      dispatch(setToken(response.data))
      await dispatch(getAccount())
      return response.data as TokenInfo
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue({
          data: error.response?.data,
          status: error.response?.status
        })
      }

      return rejectWithValue(error)
    }
  },
)

export const signUpVisitorWeb = createAsyncThunk(
  'user/signUpVisitorWeb',
  async (data: SignUpParams, { rejectWithValue }) => {
    try {
      await AccountApi.signUpVisitorWeb(data)
      return null
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue({
          data: error.response?.data,
          status: error.response?.status
        })
      }

      return rejectWithValue(error)
    }
  },
)

export const startVisitorEventExperience = createAsyncThunk(
  'user/startVisitorEventExperience',
  async (data: StartExperienceParams, { rejectWithValue }) => {
    try {
      const res = await AccountApi.startVisitorEventExperience(data);
      if (res.status === 200 && res.data.visitorAccessToken) {
        localStorage.setItem('visitorAccessToken', res.data.visitorAccessToken)
      }

      return res.data.visitorAccessToken
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  },
)

export const sendResetPasswordRequest = createAsyncThunk(
  'user/sendResetPasswordRequest',
  async (email: string, { rejectWithValue }) => {
    try {
      await AccountApi.sendResetPasswordRequest(email)
      return null
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  },
)

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (data: ResetPasswordParams, { rejectWithValue }) => {
    try {
      await AccountApi.resetPassword(data)
      return null
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  },
)

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (data: ChangePasswordParams, { rejectWithValue }) => {
    try {
      await AccountApi.changePassword(data)
      return null
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  },
)

export const confirmAccount = createAsyncThunk(
  'user/confirmAccount',
  async (data: ResetPasswordParams, { rejectWithValue }) => {
    try {
      await AccountApi.confirmAccount(data)
      return null
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  },
)
