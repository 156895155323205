import { useAppDispatch } from 'redux/hooks';
import AccountApi from 'api/accountApi';
import { useTranslation } from 'react-i18next';
import { setToken } from 'redux/features/user/userSlice';
import { getAccount } from 'redux/features/user/userActions';
import useShowToast from './useShowToast';
import type { GoogleSignIn } from 'types/account';
import { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { useRedirectPath } from 'features/auth';

const REDIRECT_LINK = process.env.REACT_APP_PUBLIC_SOCIAL_REDIRECT_LINK || '';
const GOOGLE_CLIENT_ID = process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID || '';
const SLUG = 'travelverse';

type AuthWindowMessageType = {
  type: string;
  data: {
    location: string;
  };
};

type HandlerWindowMessageType = (event: MessageEvent<AuthWindowMessageType>) => Promise<void>;

const authSettings = {
  google: {
    api: 'auth/oauth2/google',
    oAuthUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    search: {
      client_id: GOOGLE_CLIENT_ID,
      response_type: 'code',
      slug: SLUG,
      scope: 'email profile',
      redirect_uri: REDIRECT_LINK,
      include_granted_scopes: 'true',
      target: 'loyalty',
    },
  },
};

export const useGoogleAuth = () => {
  const [isLoadingSocialLogin, setIsLoadingSocialLogin] = useState(false);
  const showToast = useShowToast();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage || '';
  const { redirectAfterAuth } = useRedirectPath();

  let windowDocument: Document;

  useEffect(() => {
    if (typeof document === 'undefined') {
      windowDocument = document;
    }
  }, []);

  const handlerWindowMessageRef = useRef<HandlerWindowMessageType>();

  const handleWindowMessage = (callback: (location: string) => void) => {
    const handlerWindowMessage = async (event: MessageEvent<AuthWindowMessageType>) => {
      if (event.data.type === 'oAuth') {
        callback(event.data.data.location);
      }
    };

    handlerWindowMessageRef.current = handlerWindowMessage;

    window.addEventListener('message', handlerWindowMessage);
  };

  const openWindow = () => {
    const windowParams =
      'resizable=yes,height=620,width=660,toolbar=no,titlebar=no,menubar=no,scrollbars=yes';
    return window.open('about:blank', '', windowParams);
  };

  const googleLogin = () => {
    const authWindow = openWindow();

    if (!authWindow) {
      return;
    }

    const searchParams = new URLSearchParams(authSettings.google.search).toString();

    handleWindowMessage(async (location: string) => {
      const { code } = queryString.parse(new URL(location).search);
      const data: GoogleSignIn = {
        oneTimeCode: code as string,
        redirectUrl: REDIRECT_LINK as string,
        lang,
      };

      fetchLogin(authSettings.google.api, data, authWindow);
    });

    authWindow.location.href = `${authSettings.google.oAuthUrl}?${searchParams}`;
  };

  const fetchLogin = async (url: string, data: GoogleSignIn, authWindow: Window) => {
    setIsLoadingSocialLogin(true);

    try {
      const response = await AccountApi.signInGoogle(data);

      if (response.data.token && response.status === 200) {
        authWindow.close();
        redirectAfterAuth();
        dispatch(setToken(response.data));
        await dispatch(getAccount());
      }
    } catch (error: any) {
      showToast({
        type: 'error',
        error,
      });
    } finally {
      if (handlerWindowMessageRef.current) {
        window.removeEventListener('message', handlerWindowMessageRef.current);
      }
      setIsLoadingSocialLogin(false);
    }
  };

  return {
    googleLogin,
    isLoadingSocialLogin,
  };
};
