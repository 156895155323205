import { useCallback } from 'react';
import useShowToast from './useShowToast';
import { useTranslation } from 'react-i18next';

export const useRenderApiError = () => {
  const showToast = useShowToast();
  const { t } = useTranslation();

  const renderError = useCallback((error: any) => {
    const message = extractMessage(error);

    showToast({
      type: 'error',
      text: t(message),
    });
  }, []);

  const extractMessage = (error: any) => {
    if (!error?.data?.messages) {
      return 'Something went wrong!';
    }

    let message = '';

    if (Array.isArray(error.data.messages)) {
      message = error.data.messages.map(({ value }: any) => `${value}`).join(' ');
    } else {
      message = error.data.messages;
    }

    return message;
  };

  return { renderError, extractMessage };
};
