import type {
  AuthResetPasswordBody,
  AuthApplyCodeBody,
  AuthSignUpBody,
  WithLang,
} from 'features/auth';
import { api } from '.';
import type { TokenInfo } from 'types/account';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation<TokenInfo, WithLang<AuthSignUpBody>>({
      query: (body) => ({
        url: '/signup',
        method: 'POST',
        body,
      }),
    }),
    requestPasswordReset: builder.mutation<void, string>({
      query: (email) => ({
        url: `/account/password/reset/request?email=${email}`,
        method: 'POST',
      }),
    }),
    applyPasswordResetCode: builder.mutation<void, AuthApplyCodeBody>({
      query: (body) => ({
        url: '/account/password/reset/request/apply',
        body,
        method: 'PATCH',
      }),
    }),
    resetPassword: builder.mutation<void, AuthResetPasswordBody>({
      query: (body) => ({
        url: '/account/password/reset',
        body,
        method: 'PUT',
      }),
    }),
    requestAcountConfirmation: builder.mutation<void, void>({
      query: () => ({
        url: '/account/confirm/request',
        method: 'POST',
      }),
    }),
    confirmAccount: builder.mutation<void, string>({
      query: (code) => ({
        url: '/account/confirm',
        method: 'PUT',
        body: {
          code,
        },
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useApplyPasswordResetCodeMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useRequestAcountConfirmationMutation,
  useConfirmAccountMutation,
} = authApi;
