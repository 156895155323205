import type { ButtonHTMLAttributes, MouseEvent } from 'react';
import { forwardRef } from 'react';
import classNames from 'classnames';
import Loader from 'components/Loaders/Loader';
import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  className?: string;
  variant?: 'filled' | 'outlined' | 'text';
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  color?: 'black' | 'primary' | 'white' | 'transparent';
  icon?: string;
  iconPosition?: 'start' | 'end';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    text,
    className,
    variant = 'filled',
    disabled = false,
    onClick = (): void => {},
    loading = false,
    color = 'black',
    icon,
    iconPosition = 'start',
    ...otherProps
  } = props;

  const btnClassnames = classNames(
    styles.button,
    styles[variant],
    styles[color],
    { loading: loading },
    className,
    {
      [styles.withIcon]: icon,
    }
  );

  return (
    <button
      disabled={loading || disabled}
      onClick={onClick}
      className={btnClassnames}
      {...otherProps}
      ref={ref}
    >
      {icon && iconPosition === 'start' && <img src={icon} alt="" />}
      {!loading ? text : <Loader />}
      {icon && iconPosition === 'end' && <img src={icon} alt="" />}
    </button>
  );
});

Button.displayName = 'Button';
export default Button;
