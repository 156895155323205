import { createAsyncThunk } from '@reduxjs/toolkit';
import type { EventInfo } from 'types/event';
import type { Entity, EntityType, EntityTypeEnum } from 'types/entity';
import EventApi from 'api/eventApi';
import EntityApi from 'api/entityApi';
import { isAxiosError } from 'axios';
import {
  setEvent,
  setEntities,
  setIsLoading,
  setWasLoadedOnce,
  setEntityTypes,
  setEventAttributes,
} from 'redux/features/event/eventSlice';

export const getEventById = createAsyncThunk(
  'event/getById',
  async ({ id, lang }: { id: number; lang?: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setEvent(null));
      dispatch(setEventAttributes(null));
      const response = await EventApi.getEventById(id, lang);
      dispatch(setEvent(response.data));

      const attributes = response.data.attributes;

      if (attributes) {
        dispatch(
          setEventAttributes(
            attributes.reduce((acc, current) => {
              if (current.name && current.value) {
                acc[current.name] = current.value;
              }
              return acc;
            }, {} as Record<string, string>)
          )
        );
      }

      return response.data as EventInfo;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    } finally {
      dispatch(setIsLoading(false));
      dispatch(setWasLoadedOnce(true));
    }
  }
);

export const getEntitiesListByEvent = createAsyncThunk(
  'event/getEntitiesById',
  async (
    { id, lang, entityTypes }: { id: number; lang?: string; entityTypes?: EntityTypeEnum[] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const response = await EntityApi.getEntitiesListByEvent(id, { lang, entityTypes });
      dispatch(setEntities(response.data));
      return response.data as Entity[];
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    } finally {
      dispatch(setIsLoading(false));
      dispatch(setWasLoadedOnce(true));
    }
  }
);

export const getEntityTypes = createAsyncThunk(
  'event/getEntityTypes',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await EntityApi.getEntityTypes();
      dispatch(setEntityTypes(response.data));
      return response.data as EntityType[];
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      return rejectWithValue(error);
    }
  }
);
