import type { UseInputReturn } from 'hooks/useInput';
import styles from './CodeInput.module.scss';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

interface Props {
  length: number;
  onComplete: (otp: string) => void;
  error?: string;
}

export const CodeInput = ({ length, onComplete, error }: Props) => {
  const { t } = useTranslation();

  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return; // Allow only numbers

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < length - 1) {
      inputsRef.current[index + 1]?.focus();
    }

    const otpString = newOtp.join('');
    if (otpString.length === length) {
      onComplete(otpString);
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('text').trim();
    if (/^\d+$/.test(pasteData) && pasteData.length === length) {
      setOtp(pasteData.split(''));
      onComplete(pasteData);
    }
  };

  return (
    <label htmlFor="" className={styles.label}>
      <span>{t('Enter Code')}</span>

      <div className={styles.inputs} style={{ gridTemplateColumns: `repeat(${length}, 1fr)` }}>
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputsRef.current[index] = el)}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            className={styles.input}
          />
        ))}
        <input
          type="hidden"
          autoComplete="one-time-code"
          value={otp.join('')}
          onChange={(e) => setOtp(e.target.value.split(''))}
        />
      </div>

      {error && <span className={styles.error}>{error}</span>}
    </label>
  );
};
