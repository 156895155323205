import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from 'redux/store';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/global.scss';
import './i18n';
import { SlideModalContextProvider } from 'context/SlideModalContext';
import { RegistrationPrompt } from 'features/auth/components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <SlideModalContextProvider>
            <RegistrationPrompt>
              <App />
            </RegistrationPrompt>
          </SlideModalContextProvider>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
    <ToastContainer />
  </>
);
