import axios from 'axios'
import type { ApiErrorData } from 'types/api'
import getToken from 'utils/getToken'

const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  timeout: 25000,
})

instance.interceptors.request.use(
  (config) => {
    const accessToken = getToken()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    const visitorAccessToken = localStorage.getItem('visitorAccessToken')
    if (visitorAccessToken) {
      config.headers['X-Visitor-Access'] = visitorAccessToken
    }

    return config
  },
  (error: ApiErrorData) => Promise.reject(error),
)

export default instance
