import axios from 'api/axios'
import type { AxiosResponse } from 'axios'
import type {
  Account,
  ChangePasswordParams,
  GoogleSignIn,
  MutableAccountData,
  ResetPasswordParams,
  ResetPasswordStatus,
  SignInParams,
  SignUpParams,
  StartVisitorExperienceResponse,
  TokenInfo,
  VisitorAccount
} from 'types/account'
import type { StartExperienceParams } from 'types/experience'

const AccountApi = {
  /**
   * API to get the current visitor account.
   * Security roles - Public API, but cookie VisitorExperience is a must.
   *
   * @return {VisitorAccount} - Account info
   */
  async getVisitorAccount(): Promise<AxiosResponse<VisitorAccount>> {
    return axios.get('/account/visitor', {
    })
  },

  /**
   * API to perform authentication.
   * Security roles - Anybody.
   *
   * @param {SignInParams} data
   * @param {string} data.login - Email of existing user
   * @param {string} data.password - User’s password
   *
   * @return {TokenInfo} - Authorization token
   */
  async signIn(data: SignInParams): Promise<AxiosResponse<TokenInfo>> {
    return axios.post('/auth/signin', data)
  },

  /**
   * API to perform sign in for visitor.
   * No explicit input is required, but cookie with visitor token must be present.
   * Security roles - Anybody.
   *
   * @param {string} token - Token from url params.
   *
   * @return {TokenInfo} - Authorization token
   */
  async signInVisitor(token: string): Promise<AxiosResponse<TokenInfo>> {
    return axios.post('/auth/signin/visitor', {}, { headers: { 'X-Visitor-Access-Token': token } })
  },

  /**
   * API to perform web based sign up for event visitor - actually sign up with minimum details and with no experience.
   *
   * @param {SignUpParams} data
   * @param {string} data.email - User’s email.
   * @param {string} [data.lang] - User’s language.
   */
  async signUpVisitorWeb(data: SignUpParams): Promise<AxiosResponse<void>> {
    return axios.post('/signup/visitor', data)
  },

  /**
   * API to perform event experience sign up for visitor.
   * Either entranceLongCode or entranceShortCode must be provided.
   * Security roles - Anybody.
   *
   * @param {StartExperienceParams} data
   * @param {string} data.email - User’s email
   * @param {number} data.eventId - Mandatory event id.
   * @param {string} [data.lang] - User's language.
   * @param {string} [data.entranceLongCode] - Long entrance code. Optional when event supports no entrance code entry.
   * @param {string} [data.entranceShortCode] - (not using now) Short entrance code.
   */
  async startVisitorEventExperience(data: StartExperienceParams): Promise<AxiosResponse<StartVisitorExperienceResponse>> {
    return axios.post<StartVisitorExperienceResponse>('/public/visitor/experience/start', data)
  },

  /**
   * API to request password change from visitor.
   * At some point of time only one active attempt is allowed.
   * In 30 minutes such attempt is expired and the visitor must request password change one more time.
   * Email with link to proceed is sent as well.
   * Security roles - Anybody.
   *
   * @param {string} email - User's email.
   */
  async sendResetPasswordRequest(email: string): Promise<AxiosResponse<void>> {
    return axios.post(`/account/password/reset/request?email=${email}`)
  },

  /**
   * API to change visitor’s password.
   * There is some moment when auto login is forbidden for the visitor, and he must change password and confirm account.
   * On changing password and confirming account access cookie is erased as well.
   * Security roles - Anybody.
   *
   * @param {ResetPasswordParams} data
   * @param {string} data.code - Secret code(taken from email)
   * @param {string} data.newPassword - New password
   * @param {string} data.repeatPassword - Repeated new password
   */
  async resetPassword(data: ResetPasswordParams): Promise<AxiosResponse<void>> {
    return axios.put('/account/password/reset', data)
  },

  /**
   * API to change password.
   * Security roles - Authenticated user.
   *
   * @param {ChangePasswordParams} data
   * @param {string} data.oldPassword - Old password
   * @param {string} data.newPassword - New password
   * @param {string} data.repeatPassword - Repeated new password
   */
  async changePassword(data: ChangePasswordParams): Promise<AxiosResponse<void>> {
    return axios.put('/account/password', data)
  },

  /**
   * API to get the current account.
   * Security roles - Authenticated user.
   *
   * @return {Account} - Account info
   */
  async getAccount(): Promise<AxiosResponse<Account>> {
    return axios.get('/account')
  },

  /**
   * API to check password reset status of some user’s attempt.
   * Security roles - Anybody.
   *
   * @param {string} code - Secret code from email.
   *
   * @return {ResetPasswordStatus} - Reset password status.
   */
  async getPasswordResetStatus(code: string): Promise<AxiosResponse<ResetPasswordStatus>> {
    return axios.get(`/account/password/reset/status?code=${code}`)
  },

  /**
   * API to confirm account.
   * Confirmation means to change user’s password in order to unblock login into the system.
   * Security roles - Anybody.
   *
   * @param {ResetPasswordParams} data
   * @param {string} data.code - Secret code(taken from email)
   * @param {string} data.newPassword - New password
   * @param {string} data.repeatPassword - Repeated new password
   */
  async confirmAccount(data: ResetPasswordParams): Promise<AxiosResponse<void>> {
    return axios.put('/account/confirm', data)
  },

  /**
   * API to update current account’s profile image by specifying file
   * Security roles - Authenticated user.
   *
   * @param {MutableAccountData} data
   * @param {string} [data.firstName] - First name. Up to 100 symbols.
   * @param {string} [data.lastName] - Last name. Up to 100 symbols.
   * @param {string} data.lang - Supported locale code.
   *
   * @return {Account} - Account info.
   */
  async updateAccount(data: MutableAccountData): Promise<AxiosResponse<Account>> {
    return axios.put('/account', data)
  },

  /**
   * API to update current account’s profile image by specifying file
   * Security roles - Authenticated user.
   *
   * @param {Blob} file - File of supported content type and size.
   *
   * @return {string} - Path to uploaded resource.
   */
  async uploadImageFile(file: Blob): Promise<AxiosResponse<string>> {
    return axios.putForm('/account/image', { file: file })
  },

  /**
   * API to delete current account’s profile image.
   * Security roles - Authenticated user.
   */
  async deleteImage(): Promise<AxiosResponse<void>> {
    return axios.delete('/account/image')
  },

  /**
   * API to perform Google authentication.
   * Security roles - Anybody.
   *
   * @param {GoogleSignIn} data
   *
   * @return {TokenInfo} - Authorization token
   */
  async signInGoogle(data: GoogleSignIn): Promise<AxiosResponse<TokenInfo>> {
    return axios.post('/auth/oauth2/google', data);
  },
}

export default AccountApi

