import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ResetStage } from 'features/auth';

interface AuthSliceState {
  reset: {
    stage: ResetStage;
    email: string;
    code: string;
    timer: number;
  };
}

const initialState: AuthSliceState = {
  reset: {
    stage: ResetStage.Email,
    code: '',
    email: '',
    timer: 0,
  },
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setAuthResetStage: (state, action: PayloadAction<ResetStage>) => {
      state.reset.stage = action.payload;
    },
    setAuthResetCode: (state, action: PayloadAction<string>) => {
      state.reset.code = action.payload;
    },
    setAuthResetEmail: (state, action: PayloadAction<string>) => {
      state.reset.email = action.payload;
    },
    setAuthResetTimer: (state, action: PayloadAction<number>) => {
      state.reset.timer = action.payload;
    },
  },
});

export const { setAuthResetStage, setAuthResetCode, setAuthResetEmail, setAuthResetTimer } =
  authSlice.actions;
