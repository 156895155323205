import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { EventInfo } from 'types/event';
import type { Entity, EntityType } from 'types/entity';
import { EntityTypeEnum } from 'types/entity';
import { getEventById } from './eventActions';

type initialStateType = {
  event: EventInfo | null;
  eventAttributes: Record<string, string> | null;
  entitiesList: Entity[] | null;
  entityTypes: EntityType[] | null;
  isLoading: boolean;
  wasLoadedOnce: boolean;
  entitiesType: EntityTypeEnum;
};

const initialState: initialStateType = {
  event: null,
  eventAttributes: null,
  entitiesList: null,
  entityTypes: null,
  isLoading: false,
  wasLoadedOnce: false,
  entitiesType: EntityTypeEnum.POI,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<EventInfo | null>) => {
      state.event = action.payload;
    },
    setEntities: (state, action: PayloadAction<Entity[] | null>) => {
      state.entitiesList = action.payload;
    },
    setEntityTypes: (state, action: PayloadAction<EntityType[] | null>) => {
      state.entityTypes = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setWasLoadedOnce: (state, action: PayloadAction<boolean>) => {
      state.wasLoadedOnce = action.payload;
    },
    setEventAttributes: (state, action: PayloadAction<Record<string, string> | null>) => {
      state.eventAttributes = action.payload;
    },
    setEventEntityType: (state, action: PayloadAction<EntityTypeEnum>) => {
      state.entitiesType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEventById.fulfilled, (state, action: PayloadAction<EventInfo | null>) => {
      if (action.payload && action.payload.attributes && action.payload.attributes.length) {
        state.eventAttributes = action.payload.attributes.reduce(
          (obj, item) => Object.assign(obj, { [String(item.name)]: item.value }),
          {} as { [key: string]: string }
        );
      }
    });
  },
});

export const {
  setEvent,
  setEntities,
  setEntityTypes,
  setIsLoading,
  setWasLoadedOnce,
  setEventAttributes,
  setEventEntityType,
} = eventSlice.actions;
