import type { FC } from 'react';
import { useEffect, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { ROLES } from 'constants/account';
import withSuspense from 'components/WithSuspense';
import FullScreenLoader from 'components/Loaders/FullScreenLoader';
import ProtectedRoute from 'components/ProtectedRoute';
import HomeLayout from 'pages/home/HomeLayout';
import Layout from 'pages/Layout';
import StatisticsPage from 'pages/admin/StatisticsPage/StatisticsPage';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import AuthLayout from 'ui/AuthLayout';

const UsersPage = withSuspense(lazy(() => import('pages/admin/UsersPage/UsersPage')));
const Graph = withSuspense(lazy(() => import('pages/admin/GraphPage/Graph')));
const AdminLayout = withSuspense(lazy(() => import('pages/admin/AdminLayout/AdminLayout')));
const DetailsPage = withSuspense(lazy(() => import('pages/details/DetailsPage')));
const HomePage = withSuspense(lazy(() => import('pages/home/HomePage')));
const EventLayout = withSuspense(lazy(() => import('pages/event/EventLayout')));
const SuccessPage = withSuspense(lazy(() => import('pages/success')));
const ErrorPage = withSuspense(lazy(() => import('pages/error')));
const EventOverviewPage = withSuspense(lazy(() => import('pages/event/EventOverviewPage')));
const EntityPage = withSuspense(lazy(() => import('pages/entity/EntityPage')));
const EventStatusPage = withSuspense(lazy(() => import('pages/event/EventStatusPage')));
const NotFoundPage = withSuspense(lazy(() => import('pages/not-found')));
const MyExperiences = withSuspense(lazy(() => import('pages/my-experiences')));
const LoginPage = withSuspense(lazy(() => import('pages/login')));
const AutoLoginPage = withSuspense(lazy(() => import('pages/login/AutoLoginPage')));
const RegisterPage = withSuspense(lazy(() => import('pages/register')));
const ChangePasswordPage = withSuspense(lazy(() => import('pages/change-password')));
const ResetPasswordPage = withSuspense(lazy(() => import('pages/reset-password')));
const PartnerLayout = withSuspense(lazy(() => import('pages/partner/PartnerLayout')));
const PartnerPage = withSuspense(lazy(() => import('pages/partner')));
const PartnerRedemptionPage = withSuspense(lazy(() => import('pages/partner/redemption')));
const WalletSetupGuidePage = withSuspense(lazy(() => import('pages/wallet-setup-guide')));
const EventFaqPage = withSuspense(lazy(() => import('pages/event/EventFaqPage')));
const LocationFaqPage = withSuspense(lazy(() => import('pages/event/LocationFaqPage')));
const AccountConfirmPage = withSuspense(lazy(() => import('pages/account-confirm')));
const ProfilePage = withSuspense(lazy(() => import('pages/profile')));
const WishlistPage = withSuspense(lazy(() => import('pages/event/WishlistPage')));

// posthog.init('phc_ap9AjLUBrQROKnb3uHOWRZEfENOSjJN3D97iIPcWZyX',
//     {
//       api_host: 'https://eu.i.posthog.com',
//       person_profiles: 'identified_only'
//     }
// )

const App: FC = () => {
  const { isLoading, wasLoadedOnce } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.resolvedLanguage]);

  return (
    <div className="App">
      {isLoading || (isLoading && !wasLoadedOnce && <FullScreenLoader />)}
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index path=":eventName?/:additionalName?" element={<HomePage />} />
          <Route path="faq" element={<DetailsPage />} />
        </Route>

        {/* <Route path="/" element={<HomeLayout />}>
          <Route index element={<OldHomePage />} />
          <Route path="details" element={<DetailsPage />} />
          <Route path="faq" element={<DetailsPage />} />
        </Route> */}

        <Route path="/login" element={<AuthLayout page="login" />}>
          <Route index element={<LoginPage />} />
          <Route path="auto" element={<AutoLoginPage />} />
        </Route>

        <Route path="/register" element={<AuthLayout page="register" />}>
          <Route index element={<RegisterPage />} />
        </Route>

        <Route path="/reset-password" element={<AuthLayout page="reset" />}>
          <Route index element={<ResetPasswordPage />} />
        </Route>

        <Route path="/change-password" element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoute redirectPath="/login">
                <ChangePasswordPage />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/wallet-setup-guide" element={<Layout />}>
          <Route index element={<WalletSetupGuidePage />} />
        </Route>

        <Route
          path="/my-experiences"
          element={
            <ProtectedRoute role={ROLES.member}>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route index element={<MyExperiences />} />
        </Route>

        <Route path="/event/:eventId" element={<EventLayout />}>
          <Route index element={<EventOverviewPage />} />
          {/* <Route path="restore" element={<RestoreExperiencePage />} /> */}
          {/* <Route path="overview" element={<EventOverviewPage />} /> */}
          <Route path="status" element={<EventStatusPage />} />
          <Route path="success" element={<SuccessPage />} />
          <Route path="faq" element={<EventFaqPage />} />
          <Route path="how-to-enable-location" element={<LocationFaqPage />} />
        </Route>

        <Route path="/partner" element={<PartnerLayout />}>
          <Route index element={<PartnerPage />} />
          <Route path="redemption" element={<PartnerRedemptionPage />} />
        </Route>

        <Route path="/event/:eventId/entity/:entityCode" element={<EventLayout />}>
          <Route index element={<EntityPage />} />

          <Route path="success" element={<SuccessPage />} />
          <Route path="faq" element={<EventFaqPage />} />
        </Route>

        <Route path="wishlist" element={<EventLayout notLoadEvent={true} />}>
          <Route index element={<WishlistPage />} />
        </Route>

        <Route
          path="/admin"
          element={
            <ProtectedRoute role={ROLES.admin}>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path="statistics" element={<StatisticsPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="graph" element={<Graph />} />
        </Route>

        <Route path="/account-confirm" element={<AccountConfirmPage />} />

        <Route path="/profile" element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoute redirectPath="/login">
                <ProfilePage />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/success" element={<SuccessPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
