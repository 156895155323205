import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import type { AuthSignUpBody } from '../types';
import { useSignUpMutation } from 'redux/api/auth';
import type { TokenInfo } from 'types/account';
import { useRenderApiError } from 'hooks/useRenderApiError';
import { useTranslation } from 'react-i18next';

const schema = yup
  .object({
    email: yup.string().email('Enter a valid email address.').required('Email is required.'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters long.')
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter.')
      .matches(/\d/, 'Password must contain at least one number.')
      .matches(/[^a-zA-Z\d]/, 'Password must contain at least one special character.')
      .required('Password is required.'),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords do not match.')
      .required('Repeat password is required.'),
  })
  .required();

export const useSignUpForm = (onSuccess?: (tokens: TokenInfo) => void) => {
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });
  const { renderError } = useRenderApiError();
  const { i18n } = useTranslation();

  const [signUp] = useSignUpMutation();

  const onSubmit: SubmitHandler<AuthSignUpBody> = async (data) => {
    try {
      if (data.password !== data.repeatPassword) {
        return;
      }

      const response = await signUp({ ...data, lang: i18n.resolvedLanguage }).unwrap();

      if (onSuccess) {
        console.log(onSuccess);

        onSuccess(response);
      }
    } catch (error) {
      renderError(error);
    }
  };

  return { onSubmit: handleSubmit(onSubmit), control, formState };
};
