import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import Logo from 'assets/images/icons/logo-white.svg';
import LanguageSelector from 'ui/LanguageSelector';

const Header = () => {
  return (
    <header className={styles.header}>
      <Link to="/">
        <img src={Logo} alt="TravelVerse" />
      </Link>

      <LanguageSelector />
    </header>
  );
};

export default Header;
