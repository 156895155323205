import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { getAllEvents } from 'redux/features/publicInfo/publicInfoActions';
import type { EventInfo } from 'types/event';
import LinearProgress from 'components/Loaders/LinearProgress';
import Accordion from 'components/Accordion';
import { setEventIdList } from 'redux/features/myExperiences/myExperiencesSlice';
import classNames from 'classnames';
import eventBlackIcon from 'assets/images/icons/event-black.svg';
import eventWhiteIcon from 'assets/images/icons/event-white.svg';
import findLocale from 'utils/findLocale';
import './EventsFilter.scss';
import type { CityWithEvents, CountryWithEvents } from 'hooks/useFilterEvents';
import { useFilterEvents } from 'hooks/useFilterEvents';

interface EventsFilterProps {
  getEventIdHandler?: (eventId: number) => void;
  changeActiveHandler?: () => void;
  changeEventNameHandler?: (eventName: string) => void;
}

const EventsFilter: FC<EventsFilterProps> = ({
  getEventIdHandler,
  changeActiveHandler,
  changeEventNameHandler,
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation('pages/my-experiences');
  const { events } = useAppSelector((state) => state.publicInfo);
  const { eventIdList } = useAppSelector((state) => state.myExperiences);
  const [searchParams, setSearchParams] = useSearchParams();

  const { filteredCountries: countriesWithEvents, loading: isLoading } = useFilterEvents(
    events?.content
  );

  const getCountryCityName = useCallback(
    (countryOrCity: CountryWithEvents | CityWithEvents) => {
      if (countryOrCity.locales?.length && i18n.resolvedLanguage) {
        return findLocale(countryOrCity.locales, i18n.resolvedLanguage).name;
      }

      return t('Unknown place');
    },
    [i18n.resolvedLanguage]
  );

  const getEventName = useCallback(
    (event: EventInfo) => {
      if (event.locales.length && i18n.resolvedLanguage) {
        return findLocale(event.locales, i18n.resolvedLanguage).name;
      }

      return t('Unknown event');
    },
    [i18n.resolvedLanguage]
  );

  const serializeFilters = useCallback(
    (filters: { [key: string]: string }): void => {
      const serialized = new URLSearchParams(filters).toString();
      setSearchParams(serialized);
    },
    [setSearchParams]
  );

  const changeEventFilter = useCallback(
    (eventId: number): void => {
      if (getEventIdHandler && changeActiveHandler && changeEventNameHandler) {
        const currentEvent = events?.content.find((item) => item.id === eventId);
        let eventName = '';

        if (currentEvent && i18n.resolvedLanguage) {
          eventName = findLocale(currentEvent.locales, i18n.resolvedLanguage).name;
        }

        if (eventName) {
          changeEventNameHandler(eventName);
          changeActiveHandler();
        }

        getEventIdHandler(eventId);
      }

      if (eventIdList.includes(eventId)) {
        dispatch(setEventIdList([]));
        setSearchParams('');
      } else {
        if (!getEventIdHandler) {
          dispatch(setEventIdList([eventId]));
          serializeFilters({ eventIdList: eventId.toString() });
        }
      }
    },
    [
      changeActiveHandler,
      changeEventNameHandler,
      dispatch,
      eventIdList,
      events?.content,
      getEventIdHandler,
      serializeFilters,
      setSearchParams,
      i18n.resolvedLanguage,
    ]
  );

  useEffect(() => {
    if (i18n.resolvedLanguage) {
      dispatch(getAllEvents(i18n.resolvedLanguage));
    }
  }, [dispatch, i18n.resolvedLanguage]);

  useEffect(() => {
    if (searchParams.get('eventIdList')) {
      changeEventFilter(Number(searchParams.get('eventIdList')));
    }
  }, []);

  return (
    <div className="EventsFilter">
      <div className="EventsFilter__title">
        {t('Events by region', { ns: 'pages/my-experiences' })}
      </div>

      {isLoading && <LinearProgress />}

      <div className="EventsFilter__accordions">
        {!isLoading &&
          countriesWithEvents.map((country) => {
            return (
              <Accordion
                title={getCountryCityName(country)}
                key={country.id}
                className="country nesting-1"
              >
                <>
                  {country.cities.length
                    ? country.cities.map((city) => {
                        return (
                          <Accordion
                            title={getCountryCityName(city)}
                            key={city.id}
                            className="city nesting-2"
                          >
                            {city.events?.length
                              ? city.events.map((event) => {
                                  const isActive = eventIdList && eventIdList.includes(event.id);

                                  return (
                                    <Accordion
                                      title={
                                        <div>
                                          <img
                                            src={isActive ? eventWhiteIcon : eventBlackIcon}
                                            alt=""
                                          />{' '}
                                          <span>{getEventName(event)}</span>
                                        </div>
                                      }
                                      key={event.id}
                                      onClick={(): void => changeEventFilter(event.id)}
                                      className={classNames('event', 'nesting-3', {
                                        active: isActive,
                                      })}
                                    />
                                  );
                                })
                              : undefined}
                          </Accordion>
                        );
                      })
                    : undefined}
                  {country.events?.length
                    ? country.events.map((event) => {
                        const isActive = eventIdList && eventIdList.includes(event.id);

                        return (
                          <Accordion
                            title={
                              <div>
                                <img src={isActive ? eventWhiteIcon : eventBlackIcon} alt="" />{' '}
                                <span>{getEventName(event)}</span>
                              </div>
                            }
                            key={event.id}
                            onClick={(): void => changeEventFilter(event.id)}
                            className={classNames('event', 'nesting-2', { active: isActive })}
                          />
                        );
                      })
                    : undefined}
                </>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
};

export default EventsFilter;
