import type { Locale } from 'types/api';

const findLocale = <T extends Locale>(locales: T[], currentLocale: string): T => {
  const searchResult = locales.find((item) => item.lang === currentLocale);
  const fallbackLocale = locales.find((item) => item.lang === 'en');

  return searchResult || fallbackLocale || locales[0];
};

export default findLocale;
