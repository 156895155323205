import { useResetPassword } from 'features/auth/hooks';
import styles from './ResetPasswordForm.module.scss';
import { CodeInput } from '../CodeInput';
import Button from 'ui/Button';
import { useTranslation } from 'react-i18next';
import Input from 'ui/Input';
import { Controller } from 'react-hook-form';
import { useMemo } from 'react';
import { ResetStage } from 'features/auth';

const CODE_LENGTH = 5;

export const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { code, form, stage, onSubmit, error, resendCode, resendTimer } = useResetPassword();

  const buttonText = useMemo(() => {
    switch (stage) {
      case ResetStage.Email:
        return 'Send';
      case ResetStage.Code:
        return 'Submit';
      case ResetStage.Password:
        return 'Reset';
      default:
        return '';
    }
  }, [stage]);

  const isButtonDisabled = useMemo(() => {
    if (stage === ResetStage.Code) return code.value.length !== CODE_LENGTH;
  }, [stage, code.value]);

  const timerText = useMemo(() => {
    if (resendTimer === 60) return ' 1:00';
    else return ` 0:${String(resendTimer).length === 2 ? resendTimer : `0${resendTimer}`}`;
  }, [resendTimer]);

  const canResend = resendTimer === 0;

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.inputs}>
        {stage === ResetStage.Email && (
          <Controller
            name="email"
            control={form.control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder={t('Enter email address')}
                label={t('Email Address')}
                disabled={form.formState.isSubmitting}
                error={t(form.formState.errors.email?.message || error)}
              />
            )}
          />
        )}

        {stage === ResetStage.Code && (
          <>
            <CodeInput onComplete={code.setCode} length={CODE_LENGTH} error={t(error)} />

            <p className={styles.resend}>
              {t("Didn't receive it?")}{' '}
              <button
                onClick={(event) => {
                  event.preventDefault();
                  if (canResend) resendCode();
                }}
                style={{
                  color: canResend ? '#2C44DA' : '#171A1C',
                  cursor: canResend ? 'pointer' : 'default',
                }}
              >
                {canResend ? t('Resend Code') : t('Resend Code in') + timerText}
              </button>
            </p>
          </>
        )}

        {stage === ResetStage.Password && (
          <>
            <Controller
              name="newPassword"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  placeholder={t('Enter your password')}
                  label={t('Password')}
                  disabled={form.formState.isSubmitting}
                  error={t(form.formState.errors.newPassword?.message || error)}
                />
              )}
            />
            <Controller
              name="repeatPassword"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  placeholder={t('Re-enter your password')}
                  label={t('Confirm Password')}
                  disabled={form.formState.isSubmitting}
                  error={t(form.formState.errors.repeatPassword?.message || '')}
                />
              )}
            />
          </>
        )}
      </div>

      <div className={styles.buttons}>
        <Button text={t(buttonText)} disabled={isButtonDisabled} />
      </div>
    </form>
  );
};
