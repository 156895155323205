import styles from './Separator.module.scss';

interface Props {
  text?: string;
}

const Separator = ({ text }: Props) => {
  return <div className={styles.separator}>{text?.length && <span>{text}</span>}</div>;
};

export default Separator;
