import { useRenderApiError } from 'hooks/useRenderApiError';
import useShowToast from 'hooks/useShowToast';
import { useTranslation } from 'react-i18next';
import { useConfirmAccountMutation, useRequestAcountConfirmationMutation } from 'redux/api/auth';

export const useConfirmAccount = () => {
  const [requestAccountConfirmation] = useRequestAcountConfirmationMutation();
  const [confirmAccount] = useConfirmAccountMutation();
  const { renderError } = useRenderApiError();
  const showToast = useShowToast();
  const { t } = useTranslation();

  const onRequestAccountConfirmation = async () => {
    try {
      await requestAccountConfirmation().unwrap();
      showToast({
        type: 'success',
        text: t('Check your email for the confirmation link.'),
      });
    } catch (error) {
      renderError(error);
    }
  };

  const onConfirmAccount = async (code: string) => {
    try {
      await confirmAccount(code).unwrap();
      showToast({
        type: 'success',
        text: t('Your account has been successfully confirmed.'),
      });
    } catch (error) {
      renderError(error);
    }
  };

  return { onRequestAccountConfirmation, onConfirmAccount };
};
