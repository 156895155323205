import type { City, Country } from 'types/utilityApi';
import { api } from '.';

export const geoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCoutryById: builder.query<Country, number>({
      query: (id) => `/public/countries/${id}`,
    }),
    getCityById: builder.query<City, number>({
      query: (id) => `/public/cities/${id}`,
    }),
  }),
});

export const { useLazyGetCityByIdQuery, useLazyGetCoutryByIdQuery } = geoApi;
