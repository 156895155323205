export enum ROLES {
  admin = 'ADMIN',
  member = 'MEMBER',
  manager = 'MANAGER',
  operator = 'OPERATOR',
  contributor = 'CONTRIBUTOR',
}

export const ROLES_LABELS = {
  [ROLES.admin]: 'Admin',
  [ROLES.member]: 'Member',
  [ROLES.manager]: 'Manager',
  [ROLES.operator]: 'Operator',
  [ROLES.contributor]: 'Contributor',
};

export enum STATUSES {
  confirmed = 'confirmed',
  pending = 'pending',
}

export const DEFAULT_ROUTES: { [key: string]: string } = {
  [ROLES.admin]: '/admin/statistics',
  [ROLES.member]: '/my-experiences',
  [ROLES.operator]: '/partner',
};

export const GET_ROUTE = (roles: { name: string }[]) => {
  if (roles.some(({ name }) => name === ROLES.admin)) return DEFAULT_ROUTES[ROLES.admin];
  if (roles.some(({ name }) => name === ROLES.member)) return DEFAULT_ROUTES[ROLES.member];
  if (roles.some(({ name }) => name === ROLES.operator)) return DEFAULT_ROUTES[ROLES.operator];
};
