import type {
  Lang,
  PageableRequest,
  PageableResponse,
  Attribute,
  Locale,
  LocaleWithValue,
} from 'types/api';
import type { Experience } from 'types/experience';
import type { ACQUISITION_TYPES, ENTITY_TYPES, RESOURCE_TYPE } from 'constants/entity';
import type { LANGUAGES } from '../constants/languages';

export interface Entity {
  latitude?: string;
  longitude?: string;
  code: string;
  type?: string;
  imageUrl?: string;
  status: string;
  acquisition: string;
  videoUrl?: string;
  arUrl?: string;
  attributes?: Attribute[];
  locales: EntityLocale[];
  rank?: number;
  startDate?: number;
  endDate?: number;
  resources?: Resource[];
  objectTypeId?: number;
  events: EntityEventsInfo[];
  eventId: number;
  features?: number[];
  paymentOptions?: number[];
  supportedLanguages?: number[];
  url?: string;
  menuUrl?: string;
  workingHours?: EntityWorkingHours[];
  averagePrice?: string;
}

export interface EntityWorkingHours {
  day: string;
  isActive: boolean;
  workFrom: string;
  workTo: string;
}

export interface EntityEventsInfo {
  eventId: number;
  type: EntityTypeEnum;
}

export interface EntityType {
  id: number;
  locales: LocaleWithValue[];
}

export interface Resource {
  type: RESOURCE_TYPE;
  position: number;
  value: string;
}

export type MappedResources = {
  [key in RESOURCE_TYPE]?: Resource[];
};

export interface EntityWithDistance extends Entity {
  distance?: {
    text: string;
    value: number;
  };
}

export interface EntitiesList extends PageableResponse {
  content: Entity[];
}

export interface EntityFilters {
  acquisitions?: string[];
  types?: string[];
  statuses?: string[];
  lang?: Lang;
  entityTypes?: EntityTypeEnum[];
}

export interface GetEntitiesParams extends PageableRequest {
  eventId?: number;
  types?: ENTITY_TYPES[];
  acquisitions?: ACQUISITION_TYPES[];
  lang?: Lang;
}

export interface EntityLocale extends Locale {
  description?: string;
}

export interface Nft {
  entity: Entity;
  experience?: Experience;
  claimedNft: boolean;
  mintedNft: boolean;
  mintingNftInProgress: boolean;
}

export interface NftsList extends PageableResponse {
  content: Nft[];
}

export interface EntityStatus {
  claimedNft: boolean;
  mintedNft: boolean;
  mintingNftInProgress: boolean;
}

export interface EntityArticle {
  id: number;
  imageUrl: string;
  classification: string;
  imageGalleryType: string;
  acquisition: string;
  status: string;
  activationRound: number;
  authorPrimaryRole: string;
  pricePolicy: string;
  price: number;
  currency: string;
  created: number;
  published: number;
  originallyPublished: number;
  createdBy: {
    id: number;
    guid: string;
    first: string;
    last: string;
    thumbnailUrl: string;
  };
  contentLocales: {
    [key: keyof typeof LANGUAGES]: {
      id: number;
      locale: string;
      title: string;
      value: string;
    };
  };
  imageGallery: string[];
  passport: {
    ip: string;
    content: number;
    system: string;
    smartContract: string;
    passportId: string;
    transactionId: string;
    status: string;
    created: number;
    selfManagement: boolean;
  };
  ownershipSold: boolean;
  preview: boolean;
  purchased: boolean;
  primaryLocale: string;
  aiScanResult: [
    {
      category: string;
      verdict: string;
      lastSuccessInvocationDate: number;
    },
    {
      category: string;
      verdict: string;
    }
  ];
  contentPreviewType: string;
  tags: [];
}

export enum EntityTypeEnum {
  POI = 'POI',
  POS = 'POS',
}
