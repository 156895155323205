import { useState, useEffect } from 'react';
import { useLazyGetCityByIdQuery, useLazyGetCoutryByIdQuery } from 'redux/api/geo';
import type { Locale } from 'types/api';
import type { EventInfo } from 'types/event';

export interface CityWithEvents {
  id: number;
  events?: EventInfo[];
  locales?: Locale[];
}

export interface CountryWithEvents {
  id: number;
  cities: CityWithEvents[];
  events?: EventInfo[];
  locales?: Locale[];
}

export const useFilterEvents = (events?: EventInfo[]) => {
  const [getCountryById] = useLazyGetCoutryByIdQuery();
  const [getCityById] = useLazyGetCityByIdQuery();

  const [filteredCountries, setFilteredCountries] = useState<CountryWithEvents[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const processEvents = async () => {
      setLoading(true);

      const countries: CountryWithEvents[] = [];

      if (events && events.length > 0) {
        events.forEach((event) => {
          const { countryId, cityId } = event;
          if (!countryId) return;

          let country = countries.find((c) => c.id === countryId);
          if (!country) {
            country = { id: countryId, cities: [], events: [] };
            countries.push(country);
          }

          if (cityId) {
            let city = country.cities.find((c) => c.id === cityId);
            if (!city) {
              city = { id: cityId, events: [event] };
              country.cities.push(city);
            } else {
              city.events = [...(city.events || []), event];
            }
          } else {
            country.events = [...(country.events || []), event];
          }
        });
      }

      const countriesWithLocales = await Promise.all(
        countries.map(async (country) => {
          const countryRes = await getCountryById(country.id);
          const countryLocales = countryRes.data?.locales;

          const citiesWithLocales = await Promise.all(
            country.cities.map(async (city) => {
              const cityRes = await getCityById(city.id);
              const cityLocales = cityRes.data?.locales;
              return {
                ...city,
                locales: cityLocales,
              };
            })
          );

          return {
            ...country,
            locales: countryLocales,
            cities: citiesWithLocales,
          };
        })
      );

      setFilteredCountries(countriesWithLocales);
      setLoading(false);
    };

    processEvents();
  }, [events, getCountryById, getCityById]);

  return { filteredCountries, loading };
};
