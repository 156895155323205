export interface AuthSignUpBody {
  email: string;
  password: string;
  repeatPassword: string;
}

export interface AuthApplyCodeBody {
  code: string;
  email: string;
}

export interface AuthResetPasswordBody {
  code: string;
  newPassword: string;
  repeatPassword: string;
}

export interface AuthResetPasswordForm {
  email?: string;
  newPassword?: string;
  repeatPassword?: string;
}

export enum ResetStage {
  Email = 'Email',
  Code = 'Code',
  Password = 'Password',
  Completed = 'Completed',
}

export type WithLang<T> = T & {
  lang?: string;
};
